<template>
  <div
    class="setting-item s-calculated-field"
    ref="calculatedField"
  >
    <div class="s-calculated-field__row">
      <div class="s-calculated-field__default-calc s-calculated-field__settings">
        <esmp-button
          v-for="(number, index) in MAX_CALCULATOR_FIGURE"
          :key="`number-${number}`"
          :class="['s-calculated-field__setting', `s-calculated-field__setting--${index}`]"
          :style="{'grid-area': `number${index}`}"
          view="outline"
          size="small"
          @click="selectOption(index)"
        >
          {{ index }}
        </esmp-button>
        <esmp-button
          v-for="(option, index) in OPTIONS"
          :key="`option-${index}`"
          :class="['s-calculated-field__setting', `s-calculated-field__setting--${index}`]"
          :style="{'grid-area': index}"
          view="outline"
          size="small"
          @click="selectOption(option.text)"
        >
          {{ option.text }}
        </esmp-button>
      </div>
      <div class="s-calculated-field__special-calc s-calculated-field__settings">
        <div class="s-calculated-field__select">
          <esmp-select2
            ref="fieldSelector"
            v-model="selectedField"
            :options="formattedInputControlList"
            placeholder="Выбор поля"
            size="small"
            @on-select="setCursor"
            @on-open-change="setCursor"
          />
          <esmp-button
            view="outline"
            size="small"
            @click="addField"
            :disabled="!selectedField"
          >
            Добавить
          </esmp-button>
        </div>
        <div class="s-calculated-field__select">
          <esmp-select2
            ref="specialOptionSelector"
            v-model="selectedSpecialOption"
            :options="setting.options"
            label-prop="name"
            placeholder="Выбор спец символа"
            size="small"
            @on-select="setCursor"
            @on-open-change="setCursor"
          />
          <esmp-button
            view="outline"
            size="small"
            @click="addSpecialOption"
            :disabled="!selectedSpecialOption"
          >
            Добавить
          </esmp-button>
        </div>
        <div class="s-calculated-field__hint typo-caption" v-if="selectedSpecialOption">
          {{ selectedSpecialOption.title }}
        </div>
      </div>
    </div>
    <validation-provider
      ref="provider"
      rules="calculatedField|required"
      :name="`«${localSetting.name}»`"
      v-slot="v"
      tag="div"
      :debounce="SEARCH_DELAY"
      :delay="SEARCH_DELAY"
      class="s-calculated-field__formula"
    >
      <esmp-input
        ref="formulaInput"
        class="s-calculated-field__settings s-calculated-field__formula-input"
        v-model="localSetting.value"
        :label="localSetting.name"
        :options="{ type: 'textarea' }"
        :error-message="v.errors[0] || ''"
        @focusout="getCursorPosition"
      />
    </validation-provider>
    <esmp-button
      :disabled="!localSetting.value"
      view="outline"
      size="small"
      @click="clearFormula"
    >
      Стереть формулу
    </esmp-button>
  </div>
</template>

<script>
import { OPTIONS, MAX_CALCULATOR_FIGURE } from '@/constants/calculatedField';
import { SEARCH_DELAY } from '@/constants/search';

export default {
  name: 'SCalculatedField',
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
    inputControlList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedField: null,
      selectedSpecialOption: null,
      cursorPosition: 0,
    };
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formattedInputControlList() {
      return this.inputControlList.map((el) => ({
        ...el,
        label: el.settings.find((s) => s.techName === 'name')?.value || '',
      }));
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'localSetting.value': function (value) {
      const strWithComma = value.includes(',');
      if (strWithComma) {
        this.getCursorPosition();
        this.localSetting.value = value.replace(/,/, '.');
        this.$nextTick(() => {
          this.setCursor();
        });
      }
    },
  },
  methods: {
    addField() {
      this.selectOption(this.selectedField, 'field');
      this.$refs.fieldSelector.clear();
    },
    addSpecialOption() {
      this.selectOption(this.selectedSpecialOption, 'specialOption');
    },
    selectOption(val, type) {
      const startString = this.localSetting?.value?.slice(0, this.cursorPosition) || '';
      const endString = this.localSetting?.value?.slice(this.cursorPosition) || '';
      let localValue = '';
      if (typeof val === 'string' || typeof val === 'number') {
        localValue += val;
      } else if (typeof val === 'object' && type === 'field') {
        localValue += `'${val.id ? 'itemId' : 'techId'}:${val.techId} (${val.label})'`;
      } else if (typeof val === 'object' && type === 'specialOption') {
        localValue += val.name;
      }
      this.cursorPosition += localValue.length;
      this.localSetting.value = startString + localValue + endString;
    },
    clearFormula() {
      this.localSetting.value = null;
    },
    getCursorPosition() {
      this.cursorPosition = this.$refs.formulaInput.$el.querySelector('textarea').selectionStart;
    },
    setCursor(e) {
      if (e?.target?.nodeName !== 'TEXTAREA') {
        this.$refs.formulaInput.focus();
        this.$refs.formulaInput.$el.querySelector('textarea').selectionStart = this.cursorPosition;
        this.$refs.formulaInput.$el.querySelector('textarea').selectionEnd = this.cursorPosition;
      }
    },
  },
  created() {
    this.OPTIONS = OPTIONS;
    this.MAX_CALCULATOR_FIGURE = MAX_CALCULATOR_FIGURE;
    this.SEARCH_DELAY = SEARCH_DELAY;
  },
  mounted() {
    this.$refs.calculatedField.addEventListener('click', this.setCursor, false);
  },
  destroyed() {
    if (this.$refs.calculatedField) {
      this.$refs.calculatedField.removeEventListener('click', this.setCursor, false);
    }
  },
};
</script>

<style lang="scss">
.s-calculated-field {
  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $base-gutter;

    .esmp-select2__container {
      min-height: 38px;
    }
  }
  &__settings {
    margin-bottom: $base-gutter;
  }
  &__default-calc {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    gap: $base-gutter / 2;
    grid-template-areas:
    "dot singleQuote semicolon plus ifMore"
    "number7 number8 number9 minus ifLess"
    "number4 number5 number6 multiplication leftParenthesis"
    "number1 number2 number3 division rightParenthesis"
    "number0 number0 number0 ifEqual ifEqual";
  }
  &__setting {
    min-width: 50px;
    font-weight: bold;
  }
  &__select {
    display: flex;
    align-items: center;
    gap: $base-gutter;
    margin-bottom: $base-gutter;
  }
  &__hint {
    border: 1px solid $color-primary-1-day;
    border-radius: $base-border-radius;
    padding: $base-gutter / 2;
  }

  &__formula {
    position: relative;

    textarea {
      font-family: 'Basis Grotesque Pro', sans-serif;
    }
  }
}
</style>
