export const OPTIONS_TYPE = {
  plus: 'plus',
  minus: 'minus',
  multiplication: 'multiplication',
  division: 'division',
  ifMore: 'ifMore',
  ifLess: 'ifLess',
  ifEqual: 'ifEqual',
  leftParenthesis: 'leftParenthesis',
  rightParenthesis: 'rightParenthesis',
  dot: 'dot',
  singleQuote: 'singleQuote',
  semicolon: 'semicolon',
};

export const OPTIONS = {
  [OPTIONS_TYPE.plus]: {
    text: '+',
  },
  [OPTIONS_TYPE.ifMore]: {
    text: '>',
  },
  [OPTIONS_TYPE.minus]: {
    text: '-',
  },
  [OPTIONS_TYPE.ifLess]: {
    text: '<',
  },
  [OPTIONS_TYPE.multiplication]: {
    text: '*',
  },
  [OPTIONS_TYPE.leftParenthesis]: {
    text: '(',
  },
  [OPTIONS_TYPE.division]: {
    text: '/',
  },
  [OPTIONS_TYPE.ifEqual]: {
    text: '=',
  },
  [OPTIONS_TYPE.rightParenthesis]: {
    text: ')',
  },
  [OPTIONS_TYPE.dot]: {
    text: '.',
  },
  [OPTIONS_TYPE.singleQuote]: {
    text: '\'',
  },
  [OPTIONS_TYPE.semicolon]: {
    text: ';',
  },
};

export const MAX_CALCULATOR_FIGURE = 10;
